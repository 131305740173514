<template>
  <div class="home">
    <div class="header">
      Parallax Info Cards
    </div>
      <div class="container">
        <ParallaxCard
          height="400px"
          width="400px"
          data-image="https://www.umwelt.nrw.de/fileadmin/redaktion/_processed_/e/9/csm_Waelder_sind_unser_Naturerbe_3134405_karin_claus_Panthermedia.net_b389c5aa72.jpg"
        >
          <h1 slot="header">Ressourcen</h1>
          <p slot="content">Bis 2030 soll kein einziger Baum mehr für unsere Produkte gefällt werden</p>
        </ParallaxCard>
        <ParallaxCard
          height="400px"
          width="400px"
          data-image="https://www.welt.de/img/wissenschaft/mobile167715515/7872504767-ci102l-w1024/Anchovis-Europaeische-Sardelle-Sardelle-Engraulis-encrasicholus-anchovy-Eur.jpg"
        >
          <h1 slot="header">Ressourcen</h1>
          <p slot="content">Bis 2030 soll kein einziger Baum mehr für unsere Produkte gefällt werden</p>
        </ParallaxCard>
      </div>
    <div class="header">
      Coding Style title
    </div>
    <typical
      class="codingWrapper"
      :steps="['The Future is green!', 1500, 'The Future is now!', 1500]"
      :wrapper="'h4'"
    ></typical>
    <div class="header">
      ComparisonSlider
    </div>
    <div class="container">
      <ComparingSlider img-left="now.png" img-right="then.png"></ComparingSlider>
    </div>
    <div class="header">
      Venetian Hover
    </div>
    <div class="container">
      <VenetianHover :margin="0"></VenetianHover>
    </div>
    <v-btn to="/scrollTest">Scroll Test</v-btn>
  </div>
</template>

<script>

import ParallaxCard from '@/components/ParallaxCard'
import typical from 'vue-typical'
import ComparingSlider from '@/components/ComparingSlider'
import VenetianHover from '@/components/VenetianHover'

export default {
  name: 'Home',
  components: {
    VenetianHover,
    ComparingSlider,
    ParallaxCard,
    typical
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.home {
  text-align: center;
}
.container {
  padding: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header {
  font-weight: bold;
}

/*Css for typical*/
.codingWrapper {
  color: #EEE;
  font-size: 66px;
}

.codingWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% { opacity: 0; }
}
</style>
