<template>
  <div :key="key" class="venetian"></div>
</template>

<script>
import { gsap, Back } from 'gsap'

export default {
  name: 'VenetianHover',
  data () {
    return {
      key: 100
    }
  },
  mounted () {
    const placeholder = document.getElementsByClassName('venetian')[0]
    let dirX
    let column, blind, blindImg
    let bg
    let colLength
    const colWidth = (placeholder.getBoundingClientRect().width / this.columns) - this.margin

    for (let i = 0; i < this.columns; i++) {
      colLength = ((colWidth + this.margin) * i)

      column = document.createElement('div')
      column.className = 'column'
      column.style.width = colWidth + 'px'
      column.style.left = colLength + 'px'
      column.style.position = 'absolute'
      column.style.height = '100%'
      column.style.top = '0'
      column.style.boxSizing = 'border-box'
      placeholder.appendChild(column)

      for (let j = 0; j < 4; j++) {
        blind = document.createElement('div')
        blind.className = 'blind'
        blind.style.width = colWidth + 'px'
        blind.style.position = 'absolute'
        blind.style.height = '100%'
        blind.style.overflow = 'hidden'
        blind.style.top = '0'
        blind.style.boxSizing = 'border-box'
        blindImg = document.createElement('div')
        blindImg.className = 'blindImg'
        blindImg.style.position = 'absolute'
        blindImg.style.height = '100%'
        blindImg.style.width = '100%'
        blindImg.style.backgroundSize = 'cover'
        blindImg.style.overflow = 'hidden'
        blindImg.style.top = '0'
        blindImg.style.boxSizing = 'border-box'

        switch (j) {
          case 0:
            gsap.set(blind, { rotationY: '0' })
            bg = this.imgSrc1
            break
          case 1:
            gsap.set(blind, { rotationY: '90' })
            bg = this.imgSrc2
            break
          case 2:
            gsap.set(blind, { rotationY: '180' })
            bg = this.imgSrc1
            break
          case 3:
            gsap.set(blind, { rotationY: '270' })
            bg = this.imgSrc2
            break
        }
        blindImg.style.width = placeholder.getBoundingClientRect().width + 'px'
        blindImg.style.backgroundImage = 'url(' + bg + ')'
        blindImg.style.left = -colLength + 'px'

        column.appendChild(blind)
        blind.appendChild(blindImg)

        gsap.set(blind, { transformOrigin: '50% 50% ' + -colWidth / 2, transformStyle: 'preserve-3d' })
      }
      gsap.set(column, { transformStyle: 'preserve-3d', transformPerspective: 1000, rotationY: 0 })
      column.addEventListener('mouseenter', function (event) {
        const elem = event.currentTarget
        const rotY = gsap.getProperty(elem, 'yRotation')
        console.log(rotY)
        if (dirX > 0) {
          gsap.to(elem, 1, { rotationY: Math.floor(rotY / 90) * 90 - 90, transformOrigin: '50% 50% -' + colWidth / 2, ease: Back.easeOut })
        } else {
          gsap.to(elem, 1, { rotationY: Math.floor(rotY / 90) * 90 - 180, transformOrigin: '50% 50% -' + colWidth / 2, ease: Back.easeOut })
        }
      })
    }
    document.addEventListener('mousemove', function (event) {
      console.log('hoo')
      dirX = event.movementX || event.mozMovementX || event.webkitMovementX || 0
    })
  },
  props: {
    imgSrc1: {
      default: 'https://www.erento.com/static/image-products/96/70b/9670be40-f285-54d5-8160-c78801fa2574_30932059_974.jpg'
    },
    imgSrc2: {
      default: 'https://images.barrons.com/im-144053?width=1280&size=1.77777778'
    },
    columns: {
      default: 16
    },
    margin: {
      default: 2
    }
  }
}
</script>

<style scoped lang="scss">
.venetian{
  display:inline-block;
  width:800px;
  height:600px;
  box-sizing:border-box;
  position:relative;
}

.hover-tip {
  height: 600px;
  width: 800px;
  margin: auto;
  position: absolute;
  z-index: 1;
  opacity: 0.6;
  mix-blend-mode: difference;
}

.column, .blind, .blindImg{
  position:absolute;
  top:0;
  height:100%;
  box-sizing:border-box;
}

.blind{
  overflow:hidden;
}

.blindImg{
  width:100%;
  background-size:cover;
}
</style>
