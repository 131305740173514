import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import StartIdea from '@/views/StartIdea'
import ScrollTest from '@/views/ScrollTest'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ScrollTest
  },
  {
    path: '/start',
    name: 'start',
    component: StartIdea
  },
  {
    path: '/scrollTest',
    name: 'scrolltest',
    component: Home
  }
]

const router = new VueRouter({
  routes
})

export default router
