<template>
  <div class="wrapper">
    <div class="images">
      <div ref="img1" class="img-1"></div>
      <div ref="img2" class="img-2"></div>
    </div>
    <div class="slider">
      <div ref="dragline" class="drag-line">
        <span></span>
      </div>
      <input v-on:input="handleInput" ref="slider" type="range" min="0" max="100" value="50">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComparingSlider',
  data () {
    return {
      slider: null,
      img2: null,
      dragLine: null
    }
  },
  props: {
    imgLeft: {
      required: true,
      type: String
    },
    imgRight: {
      required: true,
      type: String
    }
  },
  mounted () {
    this.slider = this.$refs.slider
    this.img1 = this.$refs.img1
    this.img2 = this.$refs.img2
    this.dragLine = this.$refs.dragline
  },
  methods: {
    handleInput () {
      const sliderVal = this.slider.value
      this.dragLine.style.left = sliderVal + '%'
      this.img2.style.width = sliderVal + '%'
    }
  }
}
</script>

<style scoped>
.wrapper{
  position: relative;
  height: 500px;
  width: 750px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
}
.wrapper .images{
  height: 100%;
  width: 100%;
  display: flex;
}
.wrapper .images .img-1{
  height: 100%;
  width: 100%;
  background: url("../../src/assets/then.png");
  /* background: url("images/car.jpg") no-repeat; */
}
.wrapper .images .img-2{
  position: absolute;
  height: 100%;
  width: 50%;
  background: url("../../src/assets/now.png");
}
.wrapper .slider{
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}
.wrapper .slider input{
  width: 100%;
  outline: none;
  background: none;
  -webkit-appearance: none;
}
.slider input::-webkit-slider-thumb{
  height: 486px;
  width: 3px;
  background: none;
  -webkit-appearance: none;
  cursor: col-resize;
}
.slider .drag-line{
  width: 3px;
  height: 486px;
  position: absolute;
  left: 49.85%;
  pointer-events: none;
}
.slider .drag-line::before,
.slider .drag-line::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 222px;
  background: #fff;
}
.slider .drag-line::before{
  top: 0;
}
.slider .drag-line::after{
  bottom: 0;
}
.slider .drag-line span{
  height: 42px;
  width: 42px;
  border: 3px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.slider .drag-line span::before,
.slider .drag-line span::after{
  position: absolute;
  content: "";
  top: 50%;
  border: 10px solid transparent;
  border-bottom-width: 0px;
  border-right-width: 0px;
  transform: translate(-50%, -50%) rotate(45deg);
}
.slider .drag-line span::before{
  left: 40%;
  border-left-color: #fff;
}
.slider .drag-line span::after{
  left: 60%;
  border-top-color: #fff;
}
</style>
