<template>
  <div>
    <section ref="fh" v-on:mousemove="($event) => {
      parallaxIt($event, 'fh', '.img-earth', -150)
      parallaxIt($event, 'fh', '.img-stars-1', 60)
      parallaxIt($event, 'fh', '.img-stars-2', -20)
      parallaxIt($event, 'fh', '.img-bg', -15)
      }" class="front-header">
      <typical
        class="codingWrapper"
        :steps="['The Future is green!', 1500, 'The Future is now!', 1500]"
        :wrapper="'h4'"
      ></typical>
      <v-img class="img-bg" src="http://media-s3-us-east-1.ceros.com/creative-services/images/2015/10/28/6951accab0f9ef3215bc8cd5c1bdd34a/00-main-stars.png"></v-img>
        <v-img class="img-earth" src="../../src/assets/earth.png"></v-img>
        <v-img class="img-stars-1" src="../../src/assets/stars.png"></v-img>
        <v-img class="img-stars-2" src="../../src/assets/stars.png"></v-img>
    </section>
    <section class="dark">
      <v-row align-content="center">
        <v-col cols="8">
          <div class="content">
            <div class="content-title">{{ text.goals.title }}</div>
            <p class="text">{{text.goals.text}}</p>
          </div>
        </v-col>
        <v-col cols="4">
          <img
            v-on:mousemove="parallaxIt($event, '.dark', '.img', -100)"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/22/Earth_Western_Hemisphere_transparent_background.png/1200px-Earth_Western_Hemisphere_transparent_background.png"
            class="img"
          >
        </v-col>
      </v-row>
    </section>
    <section>
      <div class="content-center">
        <ParallaxCard
          height="400px"
          width="400px"
          data-image="https://www.umwelt.nrw.de/fileadmin/redaktion/_processed_/e/9/csm_Waelder_sind_unser_Naturerbe_3134405_karin_claus_Panthermedia.net_b389c5aa72.jpg"
        >
          <h1 slot="header">Ressourcen</h1>
          <p slot="content">Bis 2030 soll kein einziger Baum mehr für unsere Produkte gefällt werden</p>
        </ParallaxCard>
        <ParallaxCard
          height="400px"
          width="400px"
          data-image="https://www.welt.de/img/wissenschaft/mobile167715515/7872504767-ci102l-w1024/Anchovis-Europaeische-Sardelle-Sardelle-Engraulis-encrasicholus-anchovy-Eur.jpg"
        >
          <h1 slot="header">Mikroplastik</h1>
          <p slot="content">Bis 2030 soll kein einziger Baum mehr für unsere Produkte gefällt werden</p>
        </ParallaxCard>
        <ParallaxCard
          height="400px"
          width="400px"
          data-image="https://www.bmel.de/SharedDocs/Bilder/EN/_Animals/guinea-pig-rabbit.jpg?__blob=wide&v=2"
        >
          <h1 slot="header">Tierwohl</h1>
          <p slot="content">Bis 2030 soll kein einziger Baum mehr für unsere Produkte gefällt werden</p>
        </ParallaxCard>
      </div>
    </section>
    <section>
      <div class="content-center">
        <VenetianHover :margin="1"></VenetianHover>
      </div>
    </section>
    <section>
      <div class="content-center">
        <ComparingSlider img-left="now.png" img-right="then.png"></ComparingSlider>
      </div>
    </section>
    <div class="content-center">
      <v-btn to="/start">Scroll Test</v-btn>
    </div>
    <section></section>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import text from '@/assets/text'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import typical from 'vue-typical'
import ParallaxCard from '@/components/ParallaxCard'
import VenetianHover from '@/components/VenetianHover'
import ComparingSlider from '@/components/ComparingSlider'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'ScrollTest',
  components: { ComparingSlider, VenetianHover, ParallaxCard, typical },
  computed: {
    text () {
      return text
    }
  },
  mounted () {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '.dark',
        start: 'center bottom'
      }
    })

    tl.from('.img', { x: 200, opacity: 0, duration: 1.5 })
      .from('.content', { y: 300, opacity: 0, duration: 1 }, '-=1')
  },
  methods: {
    parallaxIt (event, container, target, movement) {
      const con = this.$refs[container + '']
      const relX = event.pageX - con.getBoundingClientRect().left
      const relY = event.pageY - con.getBoundingClientRect().top // Todo

      gsap.to(target + '', 1, {
        x: (relX - con.clientWidth / 2) / con.clientWidth * movement,
        y: (relY - con.clientWidth / 2) / con.clientHeight * movement
      })
    }
  }
}
</script>

<style scoped lang="scss">

section {
  text-align: center;
}

.codingWrapper {
  position: relative;
  color: white;
  /* centers this div */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 4em;
  z-index: 1;
}

.img-bg {
  position: absolute;
  top: -30px;
  left: -10px;
  min-width: 110vw;
  height: 110vh;
}

.img-earth {
  height: 500px;
  width: 500px;
  position: absolute;
  left: calc(50% - 250px);
  top: calc(50vh - 250px);
}

.img-stars-1 {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.img-stars-2 {
  position: absolute;
  width: 100vh;
  right: 0;
  top: 0;
}

.codingWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% { opacity: 0; }
}

.front-header {
  height: 100vh;
}

.dark {
  text-align: left;
  color: #797979;
}

.img {
  width: auto;
  height: 100vh;
  object-fit: cover;
  right: -300px;
  top: 0;
  z-index: 0;
}

.content {
  padding: 9em 5em;
  z-index: 1;
  position: relative;

  .content-title {
    line-height: 1.2;
    font-size: 5em;
    margin-bottom: 0;
  }

  p {
    font-size: 1.6em;
  }
}
.content-center {
  padding: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
