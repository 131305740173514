<template>
  <div class='root'>
    <img class='background' style="" ref='background' src='../assets/forest.jpeg'/>
    <img class='foreground' style="" ref='foreground' src='../assets/forest_border.png'/>
    <div class='section section-1' ref='first'>
      <div>
        <slot name="start-item"></slot>
      </div>
    </div>
    <div class='section section-2' ref='second'>
      <div>
        <slot name="end-item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParallaxImageScroll',
  data () {
    return {
      foreground: '',
      background: '',
      first: '',
      second: ''
    }
  },
  mounted () {
    this.foreground = this.$refs.foreground
    this.background = this.$refs.background
    this.first = this.$refs.first
    this.second = this.$refs.second
    document.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll (e) {
      const scrollY = window.scrollY

      console.log(this.first.style)
      // decreases as user scrolls
      this.first.style.opacity = (100 - ((scrollY + window.innerHeight) - this.first.offsetHeight)) / 100
      // increases as user scrolls
      this.second.style.opacity = ((scrollY + window.innerHeight) - this.second.offsetTop) / 100

      const maxBackgroundSize = 110
      const backgroundSize = ((scrollY) / (maxBackgroundSize - 100)) // increases as user scrolls

      // zoom the background at a slower rate
      this.background.style.transform = 'scale(' + (100 + backgroundSize * 0.4) / 100 + ')'
      this.foreground.style.transform = 'scale(' + (100 + backgroundSize) / 100 + ')'
    }
  }
}
</script>

<style scoped lang="scss">
img.background, img.foreground {
  /* Fill background */
  min-height: 100%;
  min-width: 1024px;

  /* Scale proportionately */
  min-width: 100%;
  height: 100vh;
  position: fixed;
}

img.background {
  /* Positioning */
  position: fixed;
  top: 0;
  left: 0;
}

.section {
  min-height: 100vh;
  position: relative;
}

.section > div {
  position: fixed;
  color: white;
  /* centers this div */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.section-1 {
  margin-bottom: 500px; /* determines the gap between our two sections */
  font-size: 4em;
}

.section-2 {
  opacity: 0; /* defaults to 0 because it's not visible */
}

.section-2 div {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  text-align: center;
  padding: 50px;
  max-width: 300px;
}

.section-2 h2 {
  font-size: 2em;
  margin-bottom: 40px;
}

.section-2 p {
  line-height: 150%;
}
</style>
