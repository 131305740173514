<template>
  <div class="container">
      <ParallaxImageScroll>
        <h4 slot="start-item">
          <typical
            class="codingWrapper"
            :steps="['The Future is green!', 1500, 'The Future is now!', 1500]"
            :wrapper="'h4'"
          ></typical>
        </h4>
        <h1 slot="end-item">
          <v-btn outlined> Start </v-btn>
        </h1>
      </ParallaxImageScroll>
  </div>
</template>

<script>
import ParallaxImageScroll from '@/components/ParallaxImageScroll'
import typical from 'vue-typical'
export default {
  name: 'StartIdea',
  components: {
    ParallaxImageScroll,
    typical
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.codingWrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}

@keyframes blink {
  50% { opacity: 0; }
}
</style>
